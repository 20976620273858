<template>
  <UiInput
    id="search-input"
    v-model="searchPhraseModel"
    icon-name="search"
    type="search"
    variant="search"
    :placeholder="t('what_are_you_looking_for')"
    :minlength="SearchCharactersLength.MIN"
    :maxlength="SearchCharactersLength.MAX"
    :container-class="['flex-1 relative']"
    autocomplete="off"
    :show-back-button="props.showBackButton"
    class="!bg-white !pl-10 placeholder:text-grey placeholder-shown:!pl-5 sm:!pl-7 placeholder-shown:sm:!pl-7 lg:placeholder:text-primary"
    v-bind="$attrs"
    data-test="app-search-input"
    @click:back="emit('click:back')"
  >
    <template #icon>
      <ClientOnly>
        <UiButton
          v-if="props.enableBarcodeScanner"
          variant="icon"
          class="absolute right-24 top-1/2 -translate-y-1/2"
          @click="emit('click:show-barcode-scanner')"
        >
          <UiIcon
            name="barcode-search"
            :width="24"
            :height="24"
            class="block text-blue lg:hidden"
          />
        </UiButton>
      </ClientOnly>

      <div
        class="absolute right-1 top-1/2 -translate-y-1/2"
        :class="[{ 'flex items-center gap-1 rounded-full text-end': props.enableSearchOptions }]"
        :style="props.enableSearchOptions && { background: 'linear-gradient(285.99deg, #EDF3FE 13.61%, #F2F7FF 100%)', zIndex: 1, minWidth: '280px' } "
      >
        <ClientOnly>
          <UiSelect
            v-if="props.enableSearchOptions"
            v-model:selected="selectedOption"
            :options="searchBarOptionsList"
            :container-class="['flex-1 max-w-full']"
            detached-dropdown
            :close-on-select="false"
            full-height-dropdown
            radio-option
            class="v-select--search-option"
            :style="{ maxWidth: '30ch' }"
            small
            @click="onSearchOptionClick"
            @update:selected="onSearchOptionValueUpdate"
          >
            <template #list-footer>
              <AppSearchInputSaveSearchOptionCheckbox
                v-model="searchBarSavedOptions.saveSelectedSearchBarSearchOption"
                container-class="px-5"
              />
            </template>
          </UiSelect>
        </ClientOnly>
        <UiButton
          variant="icon"
          class="ml-auto rounded-full bg-orange px-8 py-3.5"
          data-test="app-search-submit"
          @click="emit('click:search', props.searchPhrase)"
        >
          <UiIcon
            name="search"
            :width="16"
            :height="16"
            class="text-white"
          />
        </UiButton>
      </div>
    </template>
  </UiInput>
</template>

<script setup lang="ts">
import { useSearch } from '@ecom/composables/useSearch/useSearch'
import { SearchCharactersLength } from '../AppSearch.types'

interface AppSearchInputProps {
  searchPhrase?: string
  tooltipClass?: string
  showBackButton?: boolean
  enableSearchOptions?: boolean
  enableBarcodeScanner?: boolean
}
const props = withDefaults(defineProps<AppSearchInputProps>(), {
  searchPhrase: '',
  tooltipClass: '!pt-0',
  showBackButton: false,
  enableSearchOptions: true,
  enableBarcodeScanner: true,
})

const emit = defineEmits<{
  (e: 'search'): void
  (e: 'update:search-phrase', value: string): void
  (e: 'click:search', value: string): void
  (e: 'click:show-barcode-scanner'): void
  (e: 'click:search-option-select'): void
  (e: 'click:back'): void
}>()

const router = useRouter()
const { t } = useI18n()
const { searchBarOptionsList, searchBarSavedOptions, selectedSearchBarOption } = useSearch()

const searchPhraseModel = defineModel('searchPhrase', { default: '', required: true })

const selectedOption = computed({
  get: () => {
    return {
      label: t('search_by', { option: selectedSearchBarOption.value.label }),
      value: selectedSearchBarOption.value.value,
    }
  },
  set: (value) => {
    searchBarSavedOptions.value.selectedOption = value
    selectedSearchBarOption.value = value
  },
})

function onSearchOptionClick() {
  emit('click:search-option-select')
}

// Mobile version is handled in AppSearchOverlay component
function onSearchOptionValueUpdate(option: typeof selectedSearchBarOption) {
  const selectedOption = option?.value ?? ''

  const currentRoute = router.currentRoute.value
  if (!currentRoute.query?.option) {
    return
  }

  if (typeof currentRoute.name === 'string' && currentRoute.name.includes('search')) {
    router.push({ query: { ...currentRoute.query, option: selectedOption } })
  }
}
</script>
